import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'select-client',
        loadChildren: () => import('./pages/select-client/select-client.module').then(m => m.SelectClientPageModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
    },
    {
        path: 'popover-icon-picker',
        loadChildren: () => import('./pages/popover-icon-picker/popover-icon-picker.module')
            .then(m => m.PopoverIconPickerPageModule)
    },
    {
        path: 'popover-color-picker',
        loadChildren: () => import('./pages/popover-color-picker/popover-color-picker.module')
            .then(m => m.PopoverColorPickerPageModule)
    },
    {
        path: 'check-point-form',
        loadChildren: () => import('./pages/check-point-form/check-point-form.module')
            .then(m => m.CheckPointFormPageModule)
    },
    {
        path: 'search',
        loadChildren: () => import('./pages/search/search.module').then(m => m.SearchPageModule)
    },
    {
        path: 'search-modal',
        loadChildren: () => import('./pages/search-modal/search-modal.module').then(m => m.SearchModalPageModule)
    },
    {
        path: 'information-delivery-modal',
        loadChildren: () => import('./pages/information-delivery-modal/information-delivery-modal.module')
            .then(m => m.InformationDeliveryModalPageModule)
    },
    {
        path: 'cargaison-manager',
        loadChildren: () => import('./pages/cargaison-manager/cargaison-manager.module')
            .then(m => m.CargaisonManagerPageModule)
    },
    {
        path: 'container-manager',
        loadChildren: () => import('./pages/container-manager/container-manager.module')
            .then(m => m.ContainerManagerPageModule)
    },
    {
        path: 'round-of-day',
        loadChildren: () => import('./pages/round-of-day/round-of-day.module').then(m => m.RoundOfDayPageModule)
    },
    {
        path: 'impression',
        loadChildren: () => import('./pages/Impression/impression.module').then(m => m.ImpressionPageModule)
    },
    {
        path: 'result-details-container',
        loadChildren: () => import('./pages/result-details-container/result-details-container.module')
            .then(m => m.ResultDetailsContainerPageModule)
    },
    {
        path: 'password-modal',
        loadChildren: () => import('./pages/password-modal/password-modal.module').then(m => m.PasswordModalPageModule)
    },
    {
        path: 'show-anomaly',
        loadChildren: () => import('./pages/show-anomaly/show-anomaly.module').then(m => m.ShowAnomalyPageModule)
    },
    {
        path: 'filter-order-for-anomaly',
        loadChildren: () => import('./pages/filter-order-for-anomaly/filter-order-for-anomaly.module')
            .then(m => m.FilterOrderForAnomalyPageModule)
    },
    {
        path: 'delivery-anomaly-manager',
        loadChildren: () => import('./pages/delivery-anomaly-manager/delivery-anomaly-manager.module')
            .then(m => m.DeliveryAnomalyManagerPageModule)
    },
    {
        path: 'markdown-viewer',
        loadChildren: () => import('./pages/markdown-viewer/markdown-viewer.module')
            .then(m => m.MarkdownViewerPageModule)
    },
    {
        path: 'container-type',
        loadChildren: () => import('./pages/container-type/container-type.module').then(m => m.ContainerTypePageModule)
    },
    {
        path: 'site-selector-custom',
        loadChildren: () => import('./pages/site-selector-custom/site-selector-custom.module')
            .then(m => m.SiteSelectorCustomPageModule)
    },
    {
        path: 'site-type',
        loadChildren: () => import('./pages/site-type/site-type.module').then(m => m.SiteTypePageModule)
    },
    {
        path: 'car',
        loadChildren: () => import('./pages/car/car.module').then(m => m.CarPageModule)
    },
    {
        path: 'car-form',
        loadChildren: () => import('./pages/car-form/car-form.module').then(m => m.CarFormPageModule)
    },
    {
        path: 'company',
        loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyPageModule)
    },
    {
        path: 'company-form',
        loadChildren: () => import('./pages/company-form/company-form.module').then(m => m.CompanyFormPageModule)
    },
    {
        path: 'container-type-form',
        loadChildren: () => import('./pages/container-type-form/container-type-form.module').then(m => m.ContainerTypeFormPageModule)
    },
    {
        path: 'site-type-form',
        loadChildren: () => import('./pages/site-type-form/site-type-form.module').then(m => m.SiteTypeFormPageModule)
    },
    {
        path: 'google-maps',
        loadChildren: () => import('./pages/google-maps/google-maps.module').then(m => m.GoogleMapsPageModule)
    },
    {
        path: 'site',
        loadChildren: () => import('./pages/site/site.module').then(m => m.SitePageModule)
    },
    {
        path: 'site-form',
        loadChildren: () => import('./pages/site-form/site-form.module').then(m => m.SiteFormPageModule)
    },
    {
        path: 'user',
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule)
    },
    {
        path: 'user-form',
        loadChildren: () => import('./pages/user-form/user-form.module').then(m => m.UserFormPageModule)
    },
    {
        path: 'round',
        loadChildren: () => import('./pages/round/round.module').then(m => m.RoundPageModule)
    },
    {
        path: 'round-form',
        loadChildren: () => import('./pages/round-form/round-form.module').then(m => m.RoundFormPageModule)
    },
    {
        path: 'round-link',
        loadChildren: () => import('./pages/round-link/round-link.module').then(m => m.RoundLinkPageModule)
    },
    {
        path: 'round-link-form',
        loadChildren: () => import('./pages/round-link-form/round-link-form.module').then(m => m.RoundLinkFormPageModule)
    },
    {
        path: 'import-client-data',
        loadChildren: () => import('./pages/import-client-data/import-client-data.module').then(m => m.ImportClientDataPageModule)
    },
    {
        path: 'view-data-to-import',
        loadChildren: () => import('./pages/view-data-to-import/view-data-to-import.module').then(m => m.ViewDataToImportPageModule)
    },
  {
    path: 'car-user-by-date',
    loadChildren: () => import('./pages/car-user-by-date/car-user-by-date.module').then( m => m.CarUserByDatePageModule)
  },
  {
    path: 'contact-form',
    loadChildren: () => import('./pages/contact-form/contact-form.module').then( m => m.ContactFormPageModule)
  },
  {
    path: 'modal-search-partner',
    loadChildren: () => import('./pages/modal-search-partner/modal-search-partner.module').then( m => m.ModalSearchPartnerPageModule)
  },
  {
    path: 'popover-change-round-driver',
    loadChildren: () => import('./pages/popover-change-round-driver/popover-change-round-driver.module').then( m => m.PopoverChangeRoundDriverPageModule)
  },
  {
    path: 'user-selector-custom',
    loadChildren: () => import('./pages/user-selector-custom/user-selector-custom.module').then( m => m.UserSelectorCustomPageModule)
  },
  {
    path: 'car-selector-custom',
    loadChildren: () => import('./pages/car-selector-custom/car-selector-custom.module').then( m => m.CarSelectorCustomPageModule)
  },
  {
    path: 'old-deliveries-view',
    loadChildren: () => import('./pages/old-deliveries-view/old-deliveries-view.module').then( m => m.OldDeliveriesViewPageModule)
  }

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
