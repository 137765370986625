import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'moment'
})
export class MomentPipe implements PipeTransform {

    transform(date: string, format: string, utc?: number) {

        if (date == null) {
            return '';
        }

        if (format === 'hourOfDay') {
            return date.substring(11, 16);
        }

        if (format === 'fromNow') {
            return moment(date).fromNow();
        }

        if (utc != null) {
            return moment(date).utcOffset(0).format(format);
        }

        return moment(date).format(format);
    }

}
