import {Injectable} from '@angular/core';
import {RootService} from '../root/root.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {RoleEnum} from '../../../entity/enum/role.enum';

@Injectable({
    providedIn: 'root'
})
export class RoleService extends RootService {

    public roles$ = new BehaviorSubject<string[]>(null);

    public functionalityList = {
        dashboard: [RoleEnum.global_manager, RoleEnum.driver_manager, RoleEnum.labo_manager],
        // --- parameter menu ---
        roundLink: [RoleEnum.global_manager, RoleEnum.driver_manager],
        site: [RoleEnum.global_manager, RoleEnum.labo_manager],
        containerType: [RoleEnum.global_manager, RoleEnum.labo_manager],
        siteType: [RoleEnum.global_manager],
        round: [RoleEnum.global_manager, RoleEnum.driver_manager],
        car: [RoleEnum.global_manager, RoleEnum.driver_manager],
        user: [RoleEnum.global_manager, RoleEnum.driver_manager, RoleEnum.labo_manager],
        printRound: [RoleEnum.global_manager, RoleEnum.driver_manager],
        anomaly: [RoleEnum.global_manager, RoleEnum.driver_manager],
        carUserByDate: [RoleEnum.global_manager, RoleEnum.driver_manager],
        // --- dashboard ---
        roundOfDay: [RoleEnum.global_manager, RoleEnum.driver_manager],
        search: [RoleEnum.global_manager, RoleEnum.driver_manager],
        cargaisonManager: [RoleEnum.global_manager, RoleEnum.driver_manager],
        containerManager: [RoleEnum.global_manager, RoleEnum.labo_manager],
        importClientData: [RoleEnum.global_manager],
        company: [RoleEnum.global_manager]
    };


    constructor(
        public http: HttpClient
    ) {
        super(http);
    }

    getAllAuthorities(): Observable<Array<string>> {
        return this.http.get<Array<string>>(this.URL + 'authorities');
    }

    getAuthoritiesWithHierachy(){
        return this.http.get(this.URL + 'users/authorities');
    }

    isAuthorised(functionalityName: string) {
        let authorised = false;
        if (!this.roles$.getValue()){this.roles$.next(JSON.parse(localStorage.getItem('roles'))); }
        const roles = this.roles$.getValue();

        if (roles != null) {
            for (const role of roles) {
                const index = this.functionalityList[functionalityName].findIndex(x => x === role);
                if (index !== -1) { authorised = true; break; }
            }
        }

        return authorised;
    }
}
